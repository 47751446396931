import HelperService from '@/services/HelperService';
import axios from '@/libs/axios';

const baseUrl = '/admin/purple-accounts';

export default {
  getAccounts(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createAccount(account) {
    return axios.post(`${baseUrl}`, account);
  },
  getAccount(uid) {
    return axios.get(`${baseUrl}/${uid}`);
  },
  updateAccount(uid, account) {
    return axios.put(`${baseUrl}/${uid}`, account);
  },
  deleteAccount(uid) {
    return axios.delete(`${baseUrl}/${uid}`);
  },
  removeOwner(uid) {
    return axios.patch(`${baseUrl}/${uid}/owner`);
  },

};
