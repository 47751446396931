<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="purple-accounts-page">
    <b-card class="" title="Manage Purple Accounts">

      <b-btn @click="$bvModal.show('modal-create-purple-account')" variant="primary"
             class="mr-2 float-right" style="margin-top: -10px;">
        Create Purple Account
      </b-btn>

      <b-card-text>This page shows a list of all available purple accounts.</b-card-text>
    </b-card>




    <b-card no-body class="mb-0">

      <div class="row p-2 justify-content-start">
      <section class="col-3">
        <label>Enterprise</label>
        <b-input-group-append>
          <model-list-select :list="enterprises"
                             @input="startNewSearch()"
                             :class="{'disabled': enterprises.length === 0}"
                             v-model="filters.enterprise_uid"
                             option-value="uid"
                             option-text="display_name"
                             placeholder="Select Enterprise"/>

          <b-button variant="primary" @click="clearEnterpriseFilter">Clear</b-button>
        </b-input-group-append>
      </section>

      <section class="col-3">
        <label>Email</label>
        <b-input-group>
          <b-form-input
            v-model="filters.email"
            @input="startNewSearch()"
            @keydown.enter="getMorePurpleAccounts(1)"
            class="d-inline-block" placeholder="Enter account email..."
          />
          <b-input-group-append>
            <b-button variant="primary" @click="getMorePurpleAccounts(1)">Search</b-button>
          </b-input-group-append>
        </b-input-group>
      </section>
    </div>

      <section>
        <b-table
          class="data-table"
          hover
          :items="purpleAccounts"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >

          <template #cell(password)="data">
            <div @click="toggleShowPassword($event, data.item.id)">
              <span v-if="(data.item.id === activePassword) || showingAllPasswords">{{ data.item.password }}</span>
              <span v-else>****************</span>
              <i class="fas ml-1" :class="data.item.id !== activePassword || showingAllPasswords ? 'fa-eye' : 'fa-eye-slash'"  />
            </div>
          </template>

          <template #cell(enterprise_uid)="data">
            <div>
              {{ getEnterpriseName(data.item.enterprise_uid) }}
            </div>
          </template>

          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>




          <template #cell(actions)="data">
            <div class="d-flex justify-content-end">
              <b-dropdown class="m-0" variant="link" right toggle-class="text-decoration-none" no-caret>
                <template #button-content>
                  <i class="fas fa-ellipsis-v"></i>
                </template>

                <b-dropdown-item-button class="full-nested-btn" v-if="$can('Manage', 'All')" @click="showPurpleAccountModal(data.item, 'modal-update-purple-account')">
                  Update
                </b-dropdown-item-button>

                <b-dropdown-item-button class="full-nested-btn" v-if="$can('Manage', 'All')" @click="showPurpleAccountModal(data.item, 'modal-remove-owner')">
                  Remove owner
                </b-dropdown-item-button>

                <b-dropdown-item-button class="full-nested-btn" v-if="$can('Manage', 'All')" @click="showPurpleAccountModal(data.item, 'modal-delete-purple-account')">
                  Delete
                </b-dropdown-item-button>


              </b-dropdown>
            </div>
          </template>

        </b-table>
        <b-row>

          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMorePurpleAccounts" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <b-col class="ml-2 mt-1" v-if="total_count === 0">
            <p>No results</p>
          </b-col>
        </b-row>
      </section>
    </b-card>

    <b-modal id="modal-create-purple-account" title="Add new Purple account" hide-footer>
      <create-purple-account :enterprises="enterprises" @refresh="refreshPurpleAccounts()" @close="closeModals()"></create-purple-account>
    </b-modal>

    <b-modal id="modal-update-purple-account" title="Update Purple Account" hide-footer>
      <update-purple-account v-if="selectedPurpleAccount" :enterprises="enterprises" :account="selectedPurpleAccount" @refresh="refreshPurpleAccounts" @close="closeModals()"></update-purple-account>
    </b-modal>

    <b-modal id="modal-delete-purple-account" title="Delete Purple account" hide-footer>
      <delete-modal
        v-if="selectedPurpleAccount"
        @close="closeModals()" @delete="deletePurpleAccount(selectedPurpleAccount)"
        note="The purple account will be removed"
        :subtitle="selectedPurpleAccount.email"
        title="Are you sure you wish to delete this purple account?"></delete-modal>
    </b-modal>

    <b-modal id="modal-remove-owner" title="Remove owner" hide-footer>
      <warning-modal title="Are you sure you wish remove owner from this purple account?"
                     ok-variant="success"
                     @ok="removeOwnerFromAccount(selectedPurpleAccount)"
                     @close="closeModals()"/>
    </b-modal>

  </div>
</template>
<!--eslint-enable-->

<script>
import PurpleAccountService from '@/services/PurpleAccountService';
import CreatePurpleAccount from '@/views/purpleAccounts/sections/CreatePurpleAccount.vue';
import UpdatePurpleAccount from '@/views/purpleAccounts/sections/UpdatePurpleAccount.vue';
import DeleteModal from '@/components/modals/DeleteModal.vue';
import warningModal from '@/components/modals/WarningModal.vue';
import EnterpriseService from '@/services/EnterpriseService';
import { ModelListSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  name: 'Accounts',
  components: {
    CreatePurpleAccount,
    UpdatePurpleAccount,
    DeleteModal,
    warningModal,
    ModelListSelect,
  },
  props: {},
  data() {
    return {
      selectedPurpleAccount: null,
      headers: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'email',
          label: 'Email',
        },
        {
          key: 'password',
          label: 'Password',
          class: 'fixed',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
        {
          key: 'owner_uid',
          label: 'Owner UID',
        },
        {
          key: 'enterprise_uid',
          label: 'Enterprise',
        },
        {
          key: 'actions',
          label: '',
          class: 'text-right',
        },
      ],
      purpleAccounts: [],
      enterprises: [],
      activePassword: 0,
      showingAllPasswords: false,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        enterprise_uid: '',
        email: '',
      },
      total_count: 0,
      timeout: null,
    };
  },
  created() {
    this.getPurpleAccounts();
    this.getEnterprises();
  },
  methods: {
    startNewSearch() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.refreshPurpleAccounts()
        this.getMoreEnterprises(1);
      }, 100);
    },
    refreshPurpleAccounts() {
      this.closeModals();
      this.getPurpleAccounts();
    },
    getMorePurpleAccounts(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getPurpleAccounts();
    },
    getMoreEnterprises(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getEnterprises();
    },
    getPurpleAccounts() {
      PurpleAccountService.getAccounts(this.filters).then(res => {
        this.purpleAccounts = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get purple accounts, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getEnterprises() {
      EnterpriseService.getEnterprises({
        page_size: 100,
      }).then(res => {
        this.enterprises = res.data.data;
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not get enterprises, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    getEnterpriseName(id) {
      let name = id
      this.enterprises.forEach(val => {
        if (val.uid === id) {
          name = val.display_name
        }
      })
      return name
    },
    deletePurpleAccount(account) {
      PurpleAccountService.deleteAccount(account.id).then(() => {
        this.$toast.success(`Deleted account ${account.email} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.refreshPurpleAccounts();
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not delete purple account, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    removeOwnerFromAccount(account) {
      PurpleAccountService.removeOwner(account.id).then(() => {
        this.$toast.success(`Removed owner from Purple account: ${account.email} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });

        this.refreshPurpleAccounts();
      }).catch(err => {
        const res = err.response
        let errorText = 'Could not remove owner from Purple account, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      });
    },
    closeModals() {
      this.$bvModal.hide('modal-create-purple-account');
      this.$bvModal.hide('modal-update-purple-account');
      this.$bvModal.hide('modal-delete-purple-account');
      this.$bvModal.hide('modal-remove-owner');
    },
    setSelectedPurpleAccount(account) {
      this.selectedPurpleAccount = account;
    },
    showPurpleAccountModal(account, modalName) {
      this.setSelectedPurpleAccount(account)
      this.$bvModal.show(modalName)
    },
    toggleShowPassword(event, accountId) {
      const classes = event.target.classList
      if (this.activePassword !== accountId) {
        classes.replace('fa-eye-slash', 'fa-eye')
        this.activePassword = accountId
      } else {
        classes.replace('fa-eye', 'fa-eye-slash')
        this.activePassword = 0
      }
    },
    clearEnterpriseFilter() {
      this.filters.enterprise_uid = ''
      this.refreshPurpleAccounts()
    },
  },
}
</script>
