<template>
  <div class="update-purple-account-modal">
    <section class="mb-2">

      <label>Email</label>
      <b-form-input v-model="purpleAccount.email"
                    required
                    type="email"
                    class="mb-1" placeholder="Enter email*"
      />

      <label>Password</label>
      <b-input-group class="mb-1">
        <b-form-input v-model="purpleAccount.password" required :type="showPassword ? 'text' : 'password'" placeholder="Enter password*" />
        <b-input-group-append>
          <b-button @click="showPassword = !showPassword">
            <i class="fas" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'" />
          </b-button>
        </b-input-group-append>
      </b-input-group>

      <label>Enterprise</label>
      <b-input-group-append>
        <model-list-select v-model="purpleAccount.enterprise_uid"
                           :list="enterprises"
                           :class="{'disabled': enterprises.length === 0}"
                           option-value="uid"
                           option-text="display_name"
                           placeholder="Select Enterprise"
        />
        <b-button variant="primary" @click="clearEnterpriseFilter">Clear</b-button>
      </b-input-group-append>
    </section>

    <section class="d-inline-flex full-width mt-2">
      <div class="ui-spacer" />
      <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
      <b-button :class="{'disabled': !checkFormValid()}"
                variant="primary" @click="updatePurpleAccount()"
      >Update purple account</b-button>
    </section>

  </div>
</template>

<script>

import PurpleAccountService from '@/services/PurpleAccountService';
import { ModelListSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  name: 'UpdatePurpleAccount',
  components: {
    ModelListSelect,
  },
  props: {
    account: {
      required: true,
      type: Object,
    },
    enterprises: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showPassword: false,
      purpleAccount: {
        email: this.account.email,
        password: this.account.password,
        enterprise_uid: this.account.enterprise_uid,
        owner_uid: this.account.owner_uid,

      },
    }
  },
  methods: {
    updatePurpleAccount() {
      PurpleAccountService.updateAccount(this.account.id, this.purpleAccount).then(() => {
        this.$toast.success(`Updated purple account ${this.purpleAccount.email} Successfully`, {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('refresh');
      }).catch(err => {
        const errorMessage = err && err.response && err.response.data && err.response.data.error;
        const msg = 'Could not update purple account, please refresh and try again';
        this.$toast.error(errorMessage ? err.response.data.error : msg, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      });
    },
    checkFormValid() {
      const emailReg = /\S+@\S+\.\S+/;
      const email = emailReg.test(this.purpleAccount.email);
      const password = this.purpleAccount.password.length > 0;

      return email && password;
    },
    clearEnterpriseFilter() {
      this.purpleAccount.enterprise_uid = ''
    },
  },
}
</script>

<style scoped>

</style>
